import styles from "../../styles/voltmesh.module.scss"
import cblockStyles from "../../styles/commons/cblock.module.scss"
import checksStyles from "../../styles/commons/circlechecks.module.scss"
import React, { useState } from "react"
import Layout from "../../components/layout/layout"
import { graphql, Link, useStaticQuery } from "gatsby"
import StartDemo from "../../components/start-demo/start-demo"
import { Quotes } from "../../components/quotes/quotes"
import { ProductsUseCases } from "../../components/products-use-cases/products-use-cases"
import FeaturedLinks from "../../components/featured-links/featured-links"
import FsLightbox from "fslightbox-react"
import ArrowSvg from "../../components/svg/arrow"
import PlaySvg from "../../components/svg/play"
import {
  VM_BLOCK_AG,
  VM_BLOCK_AS,
  VM_BLOCK_LB,
  VM_BLOCK_MULTI_TENANCY,
  VM_BLOCK_OBSERVABILITY,
  VM_BLOCK_PROG,
  VM_BLOCK_SB,
  VM_BLOCK_SM,
  VM_BLOCK_SN,
} from "../../utils/constants"
import SEO from "../../components/seo"
import withAppContext from "../../contexts/app.context"
import { ENV_CONSOLE_BASE_URL, ENV_DOCS_BASE_URL } from "../../utils/secrets"
import { Helper } from "../../utils/helper"
import AlternatingFeatures from "../../components/alternating-features/alternating-features"

const VoltMeshPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "products__voltmesh" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
        metaTwImageName,
      },
    },
  } = queryResult

  const [selectedBlock, setSelectedBlock] = useState(null)
  const [openedSchemaIndex, setOpenedSchemaIndex] = useState(1)

  const setSelectedBlockWrapper = (event, value) => {
    event.stopPropagation()
    setSelectedBlock(value)
  }

  const toggleOpenedSchemaIndex = (event, value) => {
    event.stopPropagation()
    if (value !== openedSchemaIndex) {
      setOpenedSchemaIndex(value)
    } else {
      setOpenedSchemaIndex(0)
    }
  }

  const overviewVideo = "https://player.vimeo.com/video/438720787?autoplay=1"

  const [videoLink, setVideoLink] = useState(overviewVideo)
  const [isVideoOpened, setVideoOpened] = useState(false)

  const openVideo = link => () => {
    Helper.triggerGtagVideo(link)
    setVideoLink(link)
    setVideoOpened(!isVideoOpened)
  }

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/products/voltmesh"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />
      <section className={styles.header}>
        <div className="container volterra-container d-flex flex-column flex-lg-row">
          <div className={`row ${styles.header__wrapper}`}>
            <h1
              className={`${styles.header__title} ${styles.header__titleHighlighted}`}>
              VoltMesh<span>&trade;</span>
            </h1>
            <h2 className={styles.header__title}>
              Connect & Secure Any App, Any Platform
            </h2>
            <p className={`mb-0 ${styles.header__description}`}>
              A SaaS-based offering that delivers high-performance networking
              and <br />
              zero-trust security across clouds and edge sites along with the
              ability <br />
              to offload apps on our global network
            </p>
            <div className={styles.header__cta}>
              <a
                aria-label="Start for Free"
                href={`${ENV_CONSOLE_BASE_URL}/signup/start`}
                className={"btn btn-primary " + styles.header__btn}>
                Start for Free
              </a>
              <button
                onClick={openVideo(overviewVideo)}
                className={`btn btn-primary ml-4 ${styles.header__btn} ${styles.header__btnPlay}`}>
                <PlaySvg />
                Watch overview
              </button>
            </div>
          </div>
          <div className={styles.header__imageWrapper}>
            <div
              aria-label="VoltMesh image"
              className={styles.header__image}
              onClick={openVideo(overviewVideo)}>
              <PlaySvg />
            </div>
          </div>
        </div>
      </section>

      {/* Highlighted Links */}
      <section className={`py-0 ${cblockStyles.cblock} ${styles.toplinks}`}>
        <div className="container">
          <div className={`row ${cblockStyles.cblock__body}`}>
            <FeaturedLinks
              props={{
                readmore: true,
                content: {
                  links: [
                    {
                      sect: "New Capability",
                      title: "Multi-Cluster Load Balancer & API Gateway",
                      url:
                        "/products/voltmesh-multi-cluster-load-balancer-and-api-gateway",
                    },
                    {
                      sect: "New Capability",
                      title: "Automated Zero-Trust & API security",
                      url:
                        "/products/voltmesh-automated-zero-trust-and-api-security",
                    },
                  ],
                },
              }}
            />
          </div>
        </div>
      </section>

      <FsLightbox
        toggler={isVideoOpened}
        sources={[
          <iframe
            src={videoLink}
            width="1920px"
            height="1080px"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />,
        ]}
      />

      {/* Challenges / Solutions */}
      <section
        className={`${cblockStyles.cblock} ${cblockStyles.cblock__bubbles__tl}`}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              App-centric network and security services for heterogeneous
              environments
            </h2>
          </div>
          <div className="row list justify-content-center justify-content-xl-between">
            <div className={styles.list__item}>
              <h3 className={styles.list__header}>Challenges</h3>
              <img
                src="/img/products/voltmesh-challenges.png"
                alt="Challenges"
                className={styles.services__diagram}
              />
              <ul
                className={`${styles.list__body} ${styles.list__bodyChallenge}`}>
                <li
                  className={`${styles.list__bodyItem} ${styles.list__bodyItemChallenge}`}>
                  <div className={styles.list__bodyItemWrapper}>
                    <h4 className={styles.list__bodyItemName}>
                      Significant Cost
                    </h4>
                    <p className={styles.list__bodyItemText}>
                      Multiple appliances, software and connectivity services
                      need to be deployed and managed for app networking and
                      security
                    </p>
                  </div>
                </li>
                <li
                  className={`${styles.list__bodyItem} ${styles.list__bodyItemChallenge}`}>
                  <div className={styles.list__bodyItemWrapper}>
                    <h4 className={styles.list__bodyItemName}>
                      Operational Complexity
                    </h4>
                    <p className={styles.list__bodyItemText}>
                      Increasing number of services with different APIs, policy,
                      and observability requires big investment in automation
                    </p>
                  </div>
                </li>
                <li
                  className={`${styles.list__bodyItem} ${styles.list__bodyItemChallenge}`}>
                  <div className={styles.list__bodyItemWrapper}>
                    <h4 className={styles.list__bodyItemName}>
                      Limited Collaboration
                    </h4>
                    <p className={styles.list__bodyItemText}>
                      Difficult to align DevOps, NetOps, and SecOps across
                      service provisioning and security policies using
                      ticket-based workflows
                    </p>
                  </div>
                </li>
                <li
                  className={`${styles.list__bodyItem} ${styles.list__bodyItemChallenge}`}>
                  <div className={styles.list__bodyItemWrapper}>
                    <h4 className={styles.list__bodyItemName}>
                      App Performance
                    </h4>
                    <p className={styles.list__bodyItemText}>
                      Traditional CDNs and hub/spoke networks were not designed
                      for immersive or large-scale SaaS apps
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className={styles.list__item}>
              <h3 className={styles.list__header}>Solutions</h3>
              <img
                src="/img/products/voltmesh-solutions.png"
                alt="Solutions"
                className={styles.services__diagram}
              />
              <ul
                className={`${styles.list__body} ${styles.list__bodySolution}`}>
                <li
                  className={`${styles.list__bodyItem} ${styles.list__bodyItemSolution}`}>
                  <div className={styles.list__bodyItemWrapper}>
                    <h4 className={styles.list__bodyItemName}>
                      Cost Optimized
                    </h4>
                    <p className={styles.list__bodyItemText}>
                      A consolidated networking + security software stack with
                      secure global connectivity lowers total costs
                    </p>
                  </div>
                </li>
                <li
                  className={`${styles.list__bodyItem} ${styles.list__bodyItemSolution}`}>
                  <div className={styles.list__bodyItemWrapper}>
                    <h4 className={styles.list__bodyItemName}>
                      SaaS-based Operations
                    </h4>
                    <p className={styles.list__bodyItemText}>
                      SaaS-based operations with single pane-of-glass for
                      policy, lifecycle management, and end-to-end observability
                    </p>
                  </div>
                </li>
                <li
                  className={`${styles.list__bodyItem} ${styles.list__bodyItemSolution}`}>
                  <div className={styles.list__bodyItemWrapper}>
                    <h4 className={styles.list__bodyItemName}>
                      Multi-Tenancy and Self-Service
                    </h4>
                    <p className={styles.list__bodyItemText}>
                      Self-service with separation of duties allows developers,
                      DevOps, NetOps, and SecOps to openly collaborate
                    </p>
                  </div>
                </li>
                <li
                  className={`${styles.list__bodyItem} ${styles.list__bodyItemSolution}`}>
                  <div className={styles.list__bodyItemWrapper}>
                    <h4 className={styles.list__bodyItemName}>
                      Application Delivery Network
                    </h4>
                    <p className={styles.list__bodyItemText}>
                      Workloads can be hosted and delivered from Volterra’s
                      distributed network, increasing app performance
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* App to App Networking & Security */}
      <section
        className={`${cblockStyles.cblock} ${cblockStyles.cblock__bubbles__tr}`}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Industry’s First App-to-App <br />
              Networking and Security Service
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className={styles.textblock}>
                <p className={cblockStyles.cblock__intro}>
                  Unified networking, application delivery and security for
                  hybrid, multi-cloud and edge environments
                </p>
                <ul
                  className={"list-unstyled my-4 " + checksStyles.circlechecks}>
                  <li className={checksStyles.circlechecks__item}>
                    Centralized management plane with distributed control and
                    data plane across cloud or edge
                  </li>
                  <li className={checksStyles.circlechecks__item}>
                    API gateway and advanced security via ML-powered discovery,
                    control and delivery
                  </li>
                  <li className={checksStyles.circlechecks__item}>
                    Multi-cluster service mesh for microservices and monolithic
                    applications
                  </li>
                </ul>
                <p>
                  Full multi-tenancy / self-service for collaboration across
                  DevOps, NetOps, SecOps
                </p>
                <p>
                  High-performance{" "}
                  <Link to="/products/volterra-global-network">
                    global network
                  </Link>{" "}
                  with the ability to run applications closer to end-users
                </p>
                <p>
                  24x7 NOC, SOC and SRE services to reduce operational burden
                </p>
              </div>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <picture>
                <source
                  srcSet="/img/products/voltmesh-graph-networking-security.png"
                  media="(min-width: 576px)"
                />
                <img
                  className="img-fluid"
                  src="/img/products/voltmesh-graph-networking-security-sm.png"
                  alt="A graph showing App-to-App Networking and Security"
                />
              </picture>
            </div>
          </div>
        </div>
      </section>

      {/* App-to-app New */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <Quotes
            imgurl="/img/products/betclic.png"
            width="140px"
            height="36px"
            imgalt="BetClic logo"
            author="Gauthier Astruc Amato"
            authortitle="Head of Operations, Betclic">
            <p>
              “To provide the best playing experience for our players and the
              most secure environment we use Volterra's VoltMesh service and
              global private backbone”
            </p>
          </Quotes>
        </div>
      </section>

      {/* Key Capabilities */}
      <section className={`${cblockStyles.cblock} ${styles.capabilities}`}>
        <div className="container">
          <div
            className={`${cblockStyles.cblock__header} ${cblockStyles.cblock__headerShort}`}>
            <h2 className={cblockStyles.cblock__head}>Key Capabilities</h2>
            <p>
              VoltMesh provides distributed services to connect and secure data
              and apps across the edge, network and multi-cloud.
            </p>
          </div>
        </div>
        <div className={`${styles.schema} row`} ves-e2e-test="voltmesh/schema">
          <div className={styles.schema__wrapper}>
            <div className={styles.schema__bodyWrapper}>
              <div className={styles.schema__bodyContainer}>
                <div
                  className={`${styles.schema__body} ${
                    openedSchemaIndex === 1 ? styles.schema__bodyOpened : ""
                  }`}>
                  <p
                    className={styles.schema__title}
                    onClick={event => toggleOpenedSchemaIndex(event, 1)}>
                    Application Services
                    <i
                      className={`${styles.schema__titleIcon}  ${
                        openedSchemaIndex === 1
                          ? styles.schema__titleIconOpened
                          : ""
                      }`}
                    />
                  </p>
                  <div className={styles.schema__bodyRow}>
                    <div
                      className={`${styles.schema__block} ${styles.schema__blockTriple}`}>
                      <span>Distributed Application Management</span>
                    </div>
                    <div
                      aria-label="API Gateway Details"
                      role="button"
                      className={`${styles.schema__block} ${styles.schema__blockEnabled}`}
                      onClick={event =>
                        setSelectedBlockWrapper(event, VM_BLOCK_AG)
                      }>
                      <span>API Gateway</span>
                      <div
                        className={
                          styles.schema__blockPopup +
                          " " +
                          styles.schema__blockPopupLefthand +
                          " " +
                          (selectedBlock === VM_BLOCK_AG
                            ? styles.schema__blockPopupAppear
                            : "") +
                          " " +
                          (selectedBlock !== VM_BLOCK_AG
                            ? styles.schema__blockPopupDisappear
                            : "")
                        }>
                        <div className="d-flex flex-row justify-content-between">
                          <h5 className={styles.schema__blockPopupTitle}>
                            API Gateway
                          </h5>
                          <img
                            src="/img/common/cross.svg"
                            alt=""
                            className={styles.schema__blockPopupCross}
                            onClick={event =>
                              setSelectedBlockWrapper(event, null)
                            }
                          />
                        </div>
                        <p className={styles.schema__blockPopupDescription}>
                          High performance, extendable and distributed API
                          gateway for hybrid applications regardless of
                          location.
                        </p>
                        <ul className={styles.schema__blockPopupList}>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Authentication</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>API routing and load balancing</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Traffic management and policy</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Transformation and markup</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Programmable, pluggable architecture</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Observability and monitoring</div>
                          </li>
                        </ul>
                        <a
                          href={`${ENV_DOCS_BASE_URL}/about-f5-distributed-cloud/mesh#key-mesh-services`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.schema__blockPopupLink}>
                          Learn more
                          <ArrowSvg />
                        </a>
                      </div>
                    </div>
                    <div
                      aria-label="App Security Details"
                      role="button"
                      className={`${styles.schema__block} ${styles.schema__blockEnabled}`}
                      onClick={event =>
                        setSelectedBlockWrapper(event, VM_BLOCK_AS)
                      }>
                      <span>App Security</span>
                      <div
                        className={
                          styles.schema__blockPopup +
                          " " +
                          styles.schema__blockPopupLefthand +
                          " " +
                          (selectedBlock === VM_BLOCK_AS
                            ? styles.schema__blockPopupAppear
                            : "") +
                          " " +
                          (selectedBlock !== VM_BLOCK_AS
                            ? styles.schema__blockPopupDisappear
                            : "")
                        }>
                        <div className="d-flex flex-row justify-content-between">
                          <h5 className={styles.schema__blockPopupTitle}>
                            Application Security
                          </h5>
                          <img
                            src="/img/common/cross.svg"
                            alt=""
                            className={styles.schema__blockPopupCross}
                            onClick={event =>
                              setSelectedBlockWrapper(event, null)
                            }
                          />
                        </div>
                        <p className={styles.schema__blockPopupDescription}>
                          Easily enable identity-driven security policies and
                          enforcement using algorithmic techniques and machine
                          learning.
                        </p>
                        <ul className={styles.schema__blockPopupList}>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>NG-WAF and anomaly detection</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Application-level DDOS</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>API endpoint detection and markup</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>API security and rate-limiting</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Managed PKI identity infrastructure for APIs, apps
                              and networking
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Vulnerability detection and mitigation</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Programmable service and identity policies
                            </div>
                          </li>
                        </ul>
                        <a
                          href={`${ENV_DOCS_BASE_URL}/services/mesh/application-security`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.schema__blockPopupLink}>
                          Learn more
                          <ArrowSvg />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className={styles.schema__bodyRow}>
                    <div
                      className={`${styles.schema__block} ${styles.schema__blockMd}`}>
                      <span>Continuous Develop&shy;ment & Verification</span>
                    </div>
                    <div
                      className={`${styles.schema__block} ${styles.schema__blockMd}`}>
                      <span>
                        App Identity <br /> and Secrets
                      </span>
                    </div>
                    <div
                      className={`${styles.schema__block} ${styles.schema__blockMd}`}>
                      <span>
                        Container <br /> Security
                      </span>
                    </div>
                    <div
                      aria-label="Load Balancing Details"
                      role="button"
                      className={`${styles.schema__block} ${styles.schema__blockMd} ${styles.schema__blockEnabled}`}
                      onClick={event =>
                        setSelectedBlockWrapper(event, VM_BLOCK_LB)
                      }>
                      <span>Load Balancing</span>
                      <div
                        className={
                          styles.schema__blockPopup +
                          " " +
                          styles.schema__blockPopupLefthand +
                          " " +
                          (selectedBlock === VM_BLOCK_LB
                            ? styles.schema__blockPopupAppear
                            : "") +
                          " " +
                          (selectedBlock !== VM_BLOCK_LB
                            ? styles.schema__blockPopupDisappear
                            : "")
                        }>
                        <div className="d-flex flex-row justify-content-between">
                          <h5 className={styles.schema__blockPopupTitle}>
                            Load Balancing
                          </h5>
                          <img
                            src="/img/common/cross.svg"
                            alt=""
                            className={styles.schema__blockPopupCross}
                            onClick={event =>
                              setSelectedBlockWrapper(event, null)
                            }
                          />
                        </div>
                        <p className={styles.schema__blockPopupDescription}>
                          Fully integrated load balancing platform, including
                          distributed proxy, service discovery and security for
                          modern and legacy applications.
                        </p>
                        <ul className={styles.schema__blockPopupList}>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Global load balancing (GSLB, Anycast)</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>HTTPs (TLS/mTLS) & TCP Proxy</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Dynamic Reverse Proxy & HTTP Connect</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Service Discovery & Health Checks</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Traffic Management</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Service Policy & Application Microsegmentation
                            </div>
                          </li>
                        </ul>
                        <a
                          href={`${ENV_DOCS_BASE_URL}/services/mesh/load-balancing`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.schema__blockPopupLink}>
                          Learn more
                          <ArrowSvg />
                        </a>
                      </div>
                    </div>
                    <div
                      aria-label="Service Mesh Details"
                      role="button"
                      className={`${styles.schema__block} ${styles.schema__blockMd} ${styles.schema__blockEnabled}`}
                      onClick={event =>
                        setSelectedBlockWrapper(event, VM_BLOCK_SM)
                      }>
                      <span>Service Mesh</span>
                      <div
                        className={
                          styles.schema__blockPopup +
                          " " +
                          styles.schema__blockPopupLefthand +
                          " " +
                          (selectedBlock === VM_BLOCK_SM
                            ? styles.schema__blockPopupAppear
                            : "") +
                          " " +
                          (selectedBlock !== VM_BLOCK_SM
                            ? styles.schema__blockPopupDisappear
                            : "")
                        }>
                        <div className="d-flex flex-row justify-content-between">
                          <h5 className={styles.schema__blockPopupTitle}>
                            Service Mesh
                          </h5>
                          <img
                            src="/img/common/cross.svg"
                            alt=""
                            className={styles.schema__blockPopupCross}
                            onClick={event =>
                              setSelectedBlockWrapper(event, null)
                            }
                          />
                        </div>
                        <p className={styles.schema__blockPopupDescription}>
                          SaaS-based multi-mesh platform, including a centrally
                          managed distributed proxy, service discovery and
                          security for modern and legacy applications.
                        </p>
                        <ul className={styles.schema__blockPopupList}>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Multi-cluster Secure & Auto Tunnels</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Service Discovery & Health-checks</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Traffic Management</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Identity Authority for AuthN/AuthZ</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Globally Distributed Load Balancing</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Service Policy & Advanced Security</div>
                          </li>
                        </ul>
                        <a
                          href={`${ENV_DOCS_BASE_URL}/services/mesh/service-mesh`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.schema__blockPopupLink}>
                          Learn more
                          <ArrowSvg />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles.schema__body} ${
                    openedSchemaIndex === 2 ? styles.schema__bodyOpened : ""
                  }`}>
                  <p
                    className={`${styles.schema__title} ${styles.schema__titleIndent}`}
                    onClick={event => toggleOpenedSchemaIndex(event, 2)}>
                    Infrastructure Services
                    <i
                      className={`${styles.schema__titleIcon}  ${
                        openedSchemaIndex === 2
                          ? styles.schema__titleIconOpened
                          : ""
                      }`}
                    />
                  </p>
                  <div className={styles.schema__bodyRow}>
                    <div
                      className={`${styles.schema__block} ${styles.schema__blockTriple}`}>
                      <span>Distributed Infrastructure Management</span>
                    </div>
                    <div
                      aria-label="Secure Networking Details"
                      role="button"
                      className={`${styles.schema__block} ${styles.schema__blockDouble} ${styles.schema__blockEnabled}`}
                      onClick={event =>
                        setSelectedBlockWrapper(event, VM_BLOCK_SN)
                      }>
                      <span>Secure Networking</span>
                      <div
                        className={
                          styles.schema__blockPopup +
                          " " +
                          styles.schema__blockPopupLefthand +
                          " " +
                          (selectedBlock === VM_BLOCK_SN
                            ? styles.schema__blockPopupAppear
                            : "") +
                          " " +
                          (selectedBlock !== VM_BLOCK_SN
                            ? styles.schema__blockPopupDisappear
                            : "")
                        }>
                        <div className="d-flex flex-row justify-content-between">
                          <h5 className={styles.schema__blockPopupTitle}>
                            Secure Networking
                          </h5>
                          <img
                            src="/img/common/cross.svg"
                            alt=""
                            className={styles.schema__blockPopupCross}
                            onClick={event =>
                              setSelectedBlockWrapper(event, null)
                            }
                          />
                        </div>
                        <p className={styles.schema__blockPopupDescription}>
                          Industry-proven network stack for highly scalable
                          connectivity and security across public clouds,
                          private clouds and the edge.
                        </p>
                        <ul className={styles.schema__blockPopupList}>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Fully integrated network firewall</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Forward proxy</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Routing and SD-WAN</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>VPN (IPsec and SSL)</div>
                          </li>
                        </ul>
                        <a
                          href={`${ENV_DOCS_BASE_URL}/services/mesh/secure-networking`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.schema__blockPopupLink}>
                          Learn more
                          <ArrowSvg />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className={styles.schema__bodyRow}>
                    <div
                      className={`${styles.schema__block} ${styles.schema__blockSmall}`}>
                      <span>Optimized OS</span>
                    </div>
                    <div
                      className={`${styles.schema__block} ${styles.schema__blockSmall}`}>
                      <span>Clustering</span>
                    </div>
                    <div
                      className={`${styles.schema__block} ${styles.schema__blockSmall}`}>
                      <span>Distributed Storage</span>
                    </div>
                    <div
                      className={`${styles.schema__block} ${styles.schema__blockSmall}`}>
                      <span>Managed Kubernetes</span>
                    </div>
                    <div
                      aria-label="Secure Backbone Details"
                      role="button"
                      className={`${styles.schema__block} ${styles.schema__blockDouble} ${styles.schema__blockDoubleMd} ${styles.schema__blockEnabled}`}
                      onClick={event =>
                        setSelectedBlockWrapper(event, VM_BLOCK_SB)
                      }>
                      <span>Secure Backbone</span>
                      <div
                        className={
                          styles.schema__blockPopup +
                          " " +
                          styles.schema__blockPopupLefthand +
                          " " +
                          (selectedBlock === VM_BLOCK_SB
                            ? styles.schema__blockPopupAppear
                            : "") +
                          " " +
                          (selectedBlock !== VM_BLOCK_SB
                            ? styles.schema__blockPopupDisappear
                            : "")
                        }>
                        <div className="d-flex flex-row justify-content-between">
                          <h5 className={styles.schema__blockPopupTitle}>
                            Secure Backbone
                          </h5>
                          <img
                            src="/img/common/cross.svg"
                            alt=""
                            className={styles.schema__blockPopupCross}
                            onClick={event =>
                              setSelectedBlockWrapper(event, null)
                            }
                          />
                        </div>
                        <p className={styles.schema__blockPopupDescription}>
                          Global network cloud infrastructure and private
                          backbone with interconnected PoPs and dense peering
                          for high performance connectivity with integrated
                          security.
                        </p>
                        <ul className={styles.schema__blockPopupList}>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Multi-terabit global backbone</div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Advanced traffic engineering for granular SLAs
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Network and application security including DDoS,
                              filtering and anomaly detection
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>
                              Physical or tunneled connection to VoltMesh
                            </div>
                          </li>
                          <li className={styles.schema__blockPopupListItem}>
                            <div>Private connectivity across backbone</div>
                          </li>
                        </ul>
                        <a
                          href={`${ENV_DOCS_BASE_URL}/services/mesh/secure-backbone`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.schema__blockPopupLink}>
                          Learn more
                          <ArrowSvg />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`${styles.schema__body} ${styles.schema__bodyProgrammability}`}>
                <div
                  aria-label="Programmability Details"
                  role="button"
                  className={`${styles.schema__block} ${styles.schema__blockVertical} ${styles.schema__blockEnabled}`}
                  onClick={event =>
                    setSelectedBlockWrapper(event, VM_BLOCK_PROG)
                  }>
                  <span>Programmability</span>
                  <div
                    className={
                      styles.schema__blockPopup +
                      " " +
                      (selectedBlock === VM_BLOCK_PROG
                        ? styles.schema__blockPopupAppear
                        : "") +
                      " " +
                      (selectedBlock !== VM_BLOCK_PROG
                        ? styles.schema__blockPopupDisappear
                        : "")
                    }>
                    <div className="d-flex flex-row justify-content-between">
                      <h5 className={styles.schema__blockPopupTitle}>
                        Data Plane Programmability
                      </h5>
                      <img
                        src="/img/common/cross.svg"
                        alt=""
                        className={styles.schema__blockPopupCross}
                        onClick={event => setSelectedBlockWrapper(event, null)}
                      />
                    </div>
                    <p className={styles.schema__blockPopupDescription}>
                      Data plane programmability through Javascript v8 and
                      customizable policies to address the evolving needs of
                      applications, business policy and regulatory compliance.
                    </p>
                    <ul className={styles.schema__blockPopupList}>
                      <li className={styles.schema__blockPopupListItem}>
                        <div>Custom data plane extensions</div>
                      </li>
                      <li className={styles.schema__blockPopupListItem}>
                        <div>
                          Data transformations, customized load balancing, HTTP
                          snooping, custom HTTP headers, direct response,
                          terminate/serve requests, etc.
                        </div>
                      </li>
                      <li className={styles.schema__blockPopupListItem}>
                        <div>
                          Programmable policy framework matching on custom tags,
                          labels and headers
                        </div>
                      </li>
                      <li className={styles.schema__blockPopupListItem}>
                        <div>Programmable DDoS and security protection</div>
                      </li>
                    </ul>
                    <a
                      href={`${ENV_DOCS_BASE_URL}/about-f5-distributed-cloud/mesh#key-mesh-services`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.schema__blockPopupLink}>
                      Learn more
                      <ArrowSvg />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${styles.schema__body} ${
                styles.schema__bodyInfrastructure
              } ${openedSchemaIndex === 3 ? styles.schema__bodyOpened : ""}`}>
              <p
                className={styles.schema__title}
                onClick={event => toggleOpenedSchemaIndex(event, 3)}>
                Infrastructure
                <i
                  className={`${styles.schema__titleIcon}  ${
                    openedSchemaIndex === 3
                      ? styles.schema__titleIconOpened
                      : ""
                  }`}
                />
              </p>
              <div className={styles.schema__bodyRow}>
                <div
                  className={`${styles.schema__block} ${styles.schema__blockInfrastructure}`}>
                  <span>
                    Edge <br /> (Volterra or Commodity)
                  </span>
                </div>
                <div
                  className={`${styles.schema__block} ${styles.schema__blockInfrastructure}`}>
                  <span>
                    Network <br /> (Volterra or Telco)
                  </span>
                </div>
                <div
                  className={`${styles.schema__block} ${styles.schema__blockInfrastructure}`}>
                  <span>
                    Cloud <br /> (Public/Private)
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${styles.schema__body} ${styles.schema__bodyVertical}`}>
            <div
              aria-label="Observability Details"
              role="button"
              className={`${styles.schema__block} ${styles.schema__blockEnabled}`}
              onClick={event =>
                setSelectedBlockWrapper(event, VM_BLOCK_OBSERVABILITY)
              }>
              <span>Observability</span>
              <div
                className={
                  styles.schema__blockPopup +
                  " " +
                  (selectedBlock === VM_BLOCK_OBSERVABILITY
                    ? styles.schema__blockPopupAppear
                    : "") +
                  " " +
                  (selectedBlock !== VM_BLOCK_OBSERVABILITY
                    ? styles.schema__blockPopupDisappear
                    : "")
                }>
                <div className="d-flex flex-row justify-content-between">
                  <h5 className={styles.schema__blockPopupTitle}>
                    Observability
                  </h5>
                  <img
                    src="/img/common/cross.svg"
                    alt=""
                    className={styles.schema__blockPopupCross}
                    onClick={event => setSelectedBlockWrapper(event, null)}
                  />
                </div>
                <p className={styles.schema__blockPopupDescription}>
                  Insights across heterogeneous cloud environments, networks and
                  application layers to provide a full view of application and
                  infrastructure performance, security and health.
                </p>
                <ul className={styles.schema__blockPopupList}>
                  <li className={styles.schema__blockPopupListItem}>
                    <div>
                      Global visibility of network and application performance
                    </div>
                  </li>
                  <li className={styles.schema__blockPopupListItem}>
                    <div>Logs and metrics, alerting and auditability</div>
                  </li>
                  <li className={styles.schema__blockPopupListItem}>
                    <div>Service-level connectivity metrics and tracing</div>
                  </li>
                  <li className={styles.schema__blockPopupListItem}>
                    <div>Custom dashboards</div>
                  </li>
                  <li className={styles.schema__blockPopupListItem}>
                    <div>Integration APIs for 3rd parties</div>
                  </li>
                </ul>
                <a
                  href={`${ENV_DOCS_BASE_URL}/services/app-stack/observability`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.schema__blockPopupLink}>
                  Learn more
                  <ArrowSvg />
                </a>
              </div>
            </div>
            <div
              aria-label="Multi-Tenancy Details"
              role="button"
              className={`${styles.schema__block} ${styles.schema__blockEnabled}`}
              onClick={event =>
                setSelectedBlockWrapper(event, VM_BLOCK_MULTI_TENANCY)
              }>
              <span>Multi-Tenancy</span>
              <div
                className={
                  styles.schema__blockPopup +
                  " " +
                  (selectedBlock === VM_BLOCK_MULTI_TENANCY
                    ? styles.schema__blockPopupAppear
                    : "") +
                  " " +
                  (selectedBlock !== VM_BLOCK_MULTI_TENANCY
                    ? styles.schema__blockPopupDisappear
                    : "")
                }>
                <div className="d-flex flex-row justify-content-between">
                  <h5 className={styles.schema__blockPopupTitle}>
                    Multi-Tenancy
                  </h5>
                  <img
                    src="/img/common/cross.svg"
                    alt=""
                    className={styles.schema__blockPopupCross}
                    onClick={event => setSelectedBlockWrapper(event, null)}
                  />
                </div>
                <p className={styles.schema__blockPopupDescription}>
                  Run third-party and/or multiple business lines’ applications
                  while providing complete isolation of compute, network and
                  storage resources. Provides the ability to run heterogeneous
                  workloads (containers, VMs) across different namespaces within
                  a tenant.
                </p>
                <ul className={styles.schema__blockPopupList}>
                  <li className={styles.schema__blockPopupListItem}>
                    <div>
                      Virtual private compute, storage and networking per
                      namespace
                    </div>
                  </li>
                  <li className={styles.schema__blockPopupListItem}>
                    <div>Multiple VPNs per namespace</div>
                  </li>
                  <li className={styles.schema__blockPopupListItem}>
                    <div>
                      Multi-tenancy across shared application and security
                      services
                    </div>
                  </li>
                </ul>
                <a
                  href={`${ENV_DOCS_BASE_URL}/about-f5-distributed-cloud/mesh#key-mesh-services`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.schema__blockPopupLink}>
                  Learn more
                  <ArrowSvg />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Why Voltmesh */}
      <section
        className={`${cblockStyles.cblock} ${cblockStyles.cblock__invert} ${cblockStyles.cblock__invertUsecases}`}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>Why VoltMesh?</h2>
          </div>
          <div className="row justify-content-between">
            <div className={`col-12 col-lg-4 ${styles.useCases__item}`}>
              <img
                className={styles.useCases__logo}
                src="/img/products/operations-icon-alt.svg"
                alt=""
              />
              <div className={styles.useCases__body}>
                <h3 className={styles.useCases__name}>
                  Operational Simplification
                </h3>
                <p className={styles.useCases__text}>
                  VoltMesh with its consolidated networking + security services,
                  SaaS-based operations and global network delivers a 12X
                  improvement in time-to-service and productivity for operations
                  teams.
                </p>
              </div>
            </div>

            <div className={`col-12 col-lg-4 ${styles.useCases__item}`}>
              <img
                className={styles.useCases__logo}
                src="/img/products/netops-icon.svg"
                alt=""
              />
              <div className={styles.useCases__body}>
                <h3 className={styles.useCases__name}>Collaboration</h3>
                <p className={styles.useCases__text}>
                  VoltMesh is a shared multi-tenant platform that allows
                  developers, DevOps, NetOps and SecOps to collaborate while
                  maintaining a separation of duties. SecOps can configure
                  policies enforced at different stages of the SDLC.
                </p>
              </div>
            </div>

            <div className={`col-12 col-lg-4 ${styles.useCases__item}`}>
              <img
                className={styles.useCases__logo}
                src="/img/products/shield-icon.svg"
                alt=""
              />
              <div className={styles.useCases__body}>
                <h3 className={styles.useCases__name}>Advanced API Security</h3>
                <p className={styles.useCases__text}>
                  As app traffic is increasingly moving to REST and gRPC,
                  VoltMesh uses advances in AI/ML to automatically discover
                  APIs, enforce security policies, and deliver them across
                  distributed users and app clusters
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Ecosystem and Integrations */}
      <div
        className={`${cblockStyles.cblock} ${styles.ecosystem} ${cblockStyles.cblock__bubbles__tl}`}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Ecosystem & Integrations
            </h2>
          </div>
          <AlternatingFeatures
            props={{
              features: [
                {
                  title: "Broad Platform & Cloud Provider Support",
                  copy:
                    "Deliver VoltMesh service to apps running on any platform on any public/private cloud. Connect and secure apps running in VMs, containers, bare metal or serverless.",
                  imgUrl: "/img/products/vm-ecosystem1.svg",
                  imgAlt:
                    "Diagram showing Google Cloud, AWS, Azure, Nutanix, Wmware, Kubernetes and OpenShift logotypes",
                },
                {
                  title: "Service Discovery & Service Mesh Integrations",
                  copy:
                    "VoltMesh supports multiple service discovery protocols simultaneously - Consul, Kubernetes, and DNS work out of the box. Istio or LinkerD service mesh can integrate with a VoltMesh ingress/egress gateway. ",
                  imgUrl: "/img/products/vm-ecosystem2.svg",
                  imgAlt:
                    "Diagram showing Kubernetes, Consul, DNS, Istio and LinkerD logotypes",
                },
                {
                  title: "Automation, Alerting & SIEM Integration",
                  copy:
                    "VoltMesh’s Terraform provider, vesctl CLI tool, and public APIs deliver to the automation need of app teams. Support for tools like Opsgenie or Slack for alerting and Splunk or Datadog for SIEM simplify life of DevOps and SecOps.",
                  imgUrl: "/img/products/vm-ecosystem3.svg",
                  imgAlt:
                    "Diagram showing Datadog,Slack, Opsgenie, PageDuty, Splunk and Terraform logotypes",
                },
              ],
            }}
          />
        </div>
      </div>

      {/* Voltmesh Use Cases */}
      <div
        className={`${cblockStyles.cblock} ${cblockStyles.cblock__bubbles__vmtlmr}  ${cblockStyles.cblock__grey}`}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>VoltMesh Use Cases</h2>
          </div>
          <ProductsUseCases>
            <div
              title="Multi-Cloud Networking"
              link={"/solutions/multi-cloud/multi-cloud-networking"}
              imgurl="/img/products/networking-icon.svg"
              imgalt="Stack of servers icon">
              <p>
                VoltMesh consolidates networking, security and application
                delivery to simplify operations, integrate policy and centralize
                observability. Volterra’s global network provides secure
                connectivity across multi-cloud and edge.
              </p>
            </div>
            <div
              title="Secure Kubernetes Gateway"
              link={"/solutions/multi-cloud/secure-kubernetes-gateway"}
              imgurl="/img/products/secure-cloud-icon.svg"
              imgalt="Cloud with lock inside it icon">
              <p>
                VoltMesh alongside our global network provides comprehensive
                networking, security and API services across one or multiple
                Kubernetes clusters within public/private clouds or edge sites.
              </p>
            </div>
            <div
              title="Web App Security"
              link={"/solutions/network/web-app-security-and-performance"}
              imgurl="/img/products/web-apps-icon.svg"
              imgalt="Group od squares icon">
              <p>
                Secure your Internet-facing apps using our global network with
                built-in DDoS and security services to protect from network, app
                and API attacks by automated bots, malware, and malicious users.
              </p>
            </div>
            <div
              title="App Delivery Network (ADN)"
              link={"/solutions/network/app-delivery-network"}
              imgurl="/img/products/network-edge-icon.svg"
              imgalt="Cloud with links icon">
              <p>
                Offload workloads from public or private clouds to our global
                application delivery network. Run app logic closer to users to
                improve latency or reduce costs, without changes to your
                existing CI/CD tools.
              </p>
            </div>
          </ProductsUseCases>
        </div>
      </div>

      <StartDemo />
    </Layout>
  )
}

export default withAppContext(VoltMeshPage)
