import React from "react"
import { Link } from "gatsby"
import styles from "./featured-links.module.scss"
import ArrowSvg from "./../svg/arrow"

const FeaturedLinks = ({ props }) => {
  const { content } = props

  return (
    <div
      className={`${styles.featlinks} ${
        props.readmore ? styles.featlinksReadmore : null
      }`}>
      {content.links &&
        content.links.map((link, i) => (
          <Link
            key={i}
            className={`w-100 ${styles.featlinks__link}`}
            aria-label={link.title}
            to={link.url}>
            <p className={styles.featlinks__sect}>{link.sect}</p>
            <h5>{link.title}</h5>
            {props.readmore && (
              <p className={styles.featlinks__readmore}>
                Read More
                <ArrowSvg />
              </p>
            )}
          </Link>
        ))}
    </div>
  )
}

export default FeaturedLinks
